//
// _errors.scss
//

.error-basic-img{
    max-width: 450px;
}

.error-500 {
    .title {
        font-size: 250px;
    }

    .error-500-img {
        position: absolute;
        top: 57px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        .title {
            font-size: 150px;
        }

        .error-500-img {
            width: 20% !important;
            top: 43px;
        }
    }

    @media (max-width: 767.98px) {
        .title {
            font-size: 68px;
            margin-top: 35px;
        }

        .error-500-img {
            position: relative;
            top: 0px;
        }
    }
}


@keyframes errorAnimation {
    0% {
        transform: translateX(0%);
    }

    15% {
        transform: translateX(-25%) rotate(-5deg);
    }

    30% {
        transform: translateX(20%) rotate(3deg);
    }

    45% {
        transform: translateX(-15%) rotate(-3deg);
    }

    60% {
        transform: translateX(10%) rotate(2deg);
    }

    75% {
        transform: translateX(-5%) rotate(-1deg);
    }

    100% {
        transform: translateX(0%);
    }
}

.error-img {
    animation: errorAnimation 20s infinite;
}