*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}


:before,
:after {
    --tw-content: ""
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Inter, ui-sans-serif, system-ui;
    font-feature-settings: normal;
    font-variation-settings: normal
}

body {
    margin: 0;
    line-height: inherit;
    overflow: scroll;
    height: 100%;
    font-family: "Nunito", sans-serif !important;
}

body::-webkit-scrollbar {
    display: none;
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

a {
    color: inherit;
    text-decoration: inherit
}

b,
strong {
    font-weight: bolder
}

code,
kbd,
samp,
pre {
    font-family: "Nunito", sans-serif;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
    font-family: "Nunito", sans-serif;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0
}

dialog {
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button,
[role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle
}

img,
video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

* {
    scrollbar-color: initial;
    scrollbar-width: initial
}

*,
:before,
:after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.container {
    width: 100%
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
        text-align: start;

    }

    .height {
        height: 75vh !important;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
        text-align: start;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
        text-align: start;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
        text-align: start;
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1350px;
        text-align: start;

    }

}

.prose {
    color: var(--tw-prose-body);
    max-width: 65ch
}

.prose :where(p):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em
}

.prose :where([class~=lead]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-lead);
    font-size: 1.25em;
    line-height: 1.6;
    margin-top: 1.2em;
    margin-bottom: 1.2em
}

.prose :where(a):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-links);
    text-decoration: underline;
    font-weight: 500
}

.prose :where(strong):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-bold);
    font-weight: 600
}

.prose :where(a strong):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit
}

.prose :where(blockquote strong):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit
}

.prose :where(thead th strong):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit
}

.prose :where(ol):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: decimal;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em
}

.prose :where(ol[type=A]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: upper-alpha
}

.prose :where(ol[type=a]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: lower-alpha
}

.prose :where(ol[type=A s]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: upper-alpha
}

.prose :where(ol[type=a s]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: lower-alpha
}

.prose :where(ol[type=I]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: upper-roman
}

.prose :where(ol[type=i]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: lower-roman
}

.prose :where(ol[type=I s]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: upper-roman
}

.prose :where(ol[type=i s]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: lower-roman
}

.prose :where(ol[type="1"]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: decimal
}

.prose :where(ul):not(:where([class~=not-prose], [class~=not-prose] *)) {
    list-style-type: disc;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em
}

.prose :where(ol>li):not(:where([class~=not-prose], [class~=not-prose] *))::marker {
    font-weight: 400;
    color: var(--tw-prose-counters)
}

.prose :where(ul>li):not(:where([class~=not-prose], [class~=not-prose] *))::marker {
    color: var(--tw-prose-bullets)
}

.prose :where(dt):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    margin-top: 1.25em
}

.prose :where(hr):not(:where([class~=not-prose], [class~=not-prose] *)) {
    border-color: var(--tw-prose-hr);
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em
}

.prose :where(blockquote):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-weight: 500;
    font-style: italic;
    color: var(--tw-prose-quotes);
    border-left-width: .25rem;
    border-left-color: var(--tw-prose-quote-borders);
    quotes: "“" "”" "‘" "’";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em
}

.prose :where(blockquote p:first-of-type):not(:where([class~=not-prose], [class~=not-prose] *)):before {
    content: open-quote
}

.prose :where(blockquote p:last-of-type):not(:where([class~=not-prose], [class~=not-prose] *)):after {
    content: close-quote
}

.prose :where(h1):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: .8888889em;
    line-height: 1.1111111
}

.prose :where(h1 strong):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-weight: 900;
    color: inherit
}

.prose :where(h2):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333
}

.prose :where(h2 strong):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-weight: 800;
    color: inherit
}

.prose :where(h3):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: .6em;
    line-height: 1.6
}

.prose :where(h3 strong):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-weight: 700;
    color: inherit
}

.prose :where(h4):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: .5em;
    line-height: 1.5
}

.prose :where(h4 strong):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-weight: 700;
    color: inherit
}

.prose :where(img):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 2em;
    margin-bottom: 2em
}

.prose :where(picture):not(:where([class~=not-prose], [class~=not-prose] *)) {
    display: block;
    margin-top: 2em;
    margin-bottom: 2em
}

.prose :where(kbd):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    color: var(--tw-prose-kbd);
    box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
    font-size: .875em;
    border-radius: .3125rem;
    padding: .1875em .375em
}

.prose :where(code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-code);
    font-weight: 600;
    font-size: .875em
}

.prose :where(code):not(:where([class~=not-prose], [class~=not-prose] *)):before {
    content: "`"
}

.prose :where(code):not(:where([class~=not-prose], [class~=not-prose] *)):after {
    content: "`"
}

.prose :where(a code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit
}

.prose :where(h1 code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit
}

.prose :where(h2 code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit;
    font-size: .875em
}

.prose :where(h3 code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit;
    font-size: .9em
}

.prose :where(h4 code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit
}

.prose :where(blockquote code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit
}

.prose :where(thead th code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: inherit
}

.prose :where(pre):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-pre-code);
    background-color: var(--tw-prose-pre-bg);
    overflow-x: auto;
    font-weight: 400;
    font-size: .875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: .375rem;
    padding: .8571429em 1.1428571em
}

.prose :where(pre code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: "Nunito", sans-serif;
    line-height: inherit
}

.prose :where(pre code):not(:where([class~=not-prose], [class~=not-prose] *)):before {
    content: none
}

.prose :where(pre code):not(:where([class~=not-prose], [class~=not-prose] *)):after {
    content: none
}

.prose :where(table):not(:where([class~=not-prose], [class~=not-prose] *)) {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: .875em;
    line-height: 1.7142857
}

.prose :where(thead):not(:where([class~=not-prose], [class~=not-prose] *)) {
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-prose-th-borders)
}

.prose :where(thead th):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    vertical-align: bottom;
    padding-right: .5714286em;
    padding-bottom: .5714286em;
    padding-left: .5714286em
}

.prose :where(tbody tr):not(:where([class~=not-prose], [class~=not-prose] *)) {
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-prose-td-borders)
}

.prose :where(tbody tr:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    border-bottom-width: 0
}

.prose :where(tbody td):not(:where([class~=not-prose], [class~=not-prose] *)) {
    vertical-align: baseline
}

.prose :where(tfoot):not(:where([class~=not-prose], [class~=not-prose] *)) {
    border-top-width: 1px;
    border-top-color: var(--tw-prose-th-borders)
}

.prose :where(tfoot td):not(:where([class~=not-prose], [class~=not-prose] *)) {
    vertical-align: top
}

.prose :where(figure>*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0;
    margin-bottom: 0
}

.prose :where(figcaption):not(:where([class~=not-prose], [class~=not-prose] *)) {
    color: var(--tw-prose-captions);
    font-size: .875em;
    line-height: 1.4285714;
    margin-top: .8571429em
}

.prose {
    --tw-prose-body: #374151;
    --tw-prose-headings: #111827;
    --tw-prose-lead: #4b5563;
    --tw-prose-links: #111827;
    --tw-prose-bold: #111827;
    --tw-prose-counters: #6b7280;
    --tw-prose-bullets: #d1d5db;
    --tw-prose-hr: #e5e7eb;
    --tw-prose-quotes: #111827;
    --tw-prose-quote-borders: #e5e7eb;
    --tw-prose-captions: #6b7280;
    --tw-prose-kbd: #111827;
    --tw-prose-kbd-shadows: 17 24 39;
    --tw-prose-code: #111827;
    --tw-prose-pre-code: #e5e7eb;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-th-borders: #d1d5db;
    --tw-prose-td-borders: #e5e7eb;
    --tw-prose-invert-body: #d1d5db;
    --tw-prose-invert-headings: #fff;
    --tw-prose-invert-lead: #9ca3af;
    --tw-prose-invert-links: #fff;
    --tw-prose-invert-bold: #fff;
    --tw-prose-invert-counters: #9ca3af;
    --tw-prose-invert-bullets: #4b5563;
    --tw-prose-invert-hr: #374151;
    --tw-prose-invert-quotes: #f3f4f6;
    --tw-prose-invert-quote-borders: #374151;
    --tw-prose-invert-captions: #9ca3af;
    --tw-prose-invert-kbd: #fff;
    --tw-prose-invert-kbd-shadows: 255 255 255;
    --tw-prose-invert-code: #fff;
    --tw-prose-invert-pre-code: #d1d5db;
    --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
    --tw-prose-invert-th-borders: #4b5563;
    --tw-prose-invert-td-borders: #374151;
    font-size: 1rem;
    line-height: 1.75
}

.prose :where(picture>img):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0;
    margin-bottom: 0
}

.prose :where(video):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 2em;
    margin-bottom: 2em
}

.prose :where(li):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: .5em;
    margin-bottom: .5em
}

.prose :where(ol>li):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-left: .375em
}

.prose :where(ul>li):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-left: .375em
}

.prose :where(.prose>ul>li p):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: .75em;
    margin-bottom: .75em
}

.prose :where(.prose>ul>li>*:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.25em
}

.prose :where(.prose>ul>li>*:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-bottom: 1.25em
}

.prose :where(.prose>ol>li>*:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.25em
}

.prose :where(.prose>ol>li>*:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-bottom: 1.25em
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: .75em;
    margin-bottom: .75em
}

.prose :where(dl):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em
}

.prose :where(dd):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: .5em;
    padding-left: 1.625em
}

.prose :where(hr+*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(h2+*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(h3+*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(h4+*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(thead th:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-left: 0
}

.prose :where(thead th:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-right: 0
}

.prose :where(tbody td, tfoot td):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding: .5714286em
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-left: 0
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-right: 0
}

.prose :where(figure):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 2em;
    margin-bottom: 2em
}

.prose :where(.prose>:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(.prose>:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-bottom: 0
}

.prose-sm {
    font-size: .875rem;
    line-height: 1.7142857
}

.prose-sm :where(p):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em
}

.prose-sm :where([class~=lead]):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: 1.2857143em;
    line-height: 1.5555556;
    margin-top: .8888889em;
    margin-bottom: .8888889em
}

.prose-sm :where(blockquote):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.3333333em;
    margin-bottom: 1.3333333em;
    padding-left: 1.1111111em
}

.prose-sm :where(h1):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: 2.1428571em;
    margin-top: 0;
    margin-bottom: .8em;
    line-height: 1.2
}

.prose-sm :where(h2):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: 1.4285714em;
    margin-top: 1.6em;
    margin-bottom: .8em;
    line-height: 1.4
}

.prose-sm :where(h3):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: 1.2857143em;
    margin-top: 1.5555556em;
    margin-bottom: .4444444em;
    line-height: 1.5555556
}

.prose-sm :where(h4):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.4285714em;
    margin-bottom: .5714286em;
    line-height: 1.4285714
}

.prose-sm :where(img):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em
}

.prose-sm :where(picture):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em
}

.prose-sm :where(picture>img):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0;
    margin-bottom: 0
}

.prose-sm :where(video):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em
}

.prose-sm :where(kbd):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: .8571429em;
    border-radius: .3125rem;
    padding: .1428571em .3571429em
}

.prose-sm :where(code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: .8571429em
}

.prose-sm :where(h2 code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: .9em
}

.prose-sm :where(h3 code):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: .8888889em
}

.prose-sm :where(pre):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: .8571429em;
    line-height: 1.6666667;
    margin-top: 1.6666667em;
    margin-bottom: 1.6666667em;
    border-radius: .25rem;
    padding: .6666667em 1em
}

.prose-sm :where(ol):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
    padding-left: 1.5714286em
}

.prose-sm :where(ul):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em;
    padding-left: 1.5714286em
}

.prose-sm :where(li):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: .2857143em;
    margin-bottom: .2857143em
}

.prose-sm :where(ol>li):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-left: .4285714em
}

.prose-sm :where(ul>li):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-left: .4285714em
}

.prose-sm :where(.prose-sm>ul>li p):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: .5714286em;
    margin-bottom: .5714286em
}

.prose-sm :where(.prose-sm>ul>li>*:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.1428571em
}

.prose-sm :where(.prose-sm>ul>li>*:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-bottom: 1.1428571em
}

.prose-sm :where(.prose-sm>ol>li>*:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.1428571em
}

.prose-sm :where(.prose-sm>ol>li>*:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-bottom: 1.1428571em
}

.prose-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: .5714286em;
    margin-bottom: .5714286em
}

.prose-sm :where(dl):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.1428571em;
    margin-bottom: 1.1428571em
}

.prose-sm :where(dt):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.1428571em
}

.prose-sm :where(dd):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: .2857143em;
    padding-left: 1.5714286em
}

.prose-sm :where(hr):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 2.8571429em;
    margin-bottom: 2.8571429em
}

.prose-sm :where(hr+*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose-sm :where(h2+*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose-sm :where(h3+*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose-sm :where(h4+*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose-sm :where(table):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: .8571429em;
    line-height: 1.5
}

.prose-sm :where(thead th):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-right: 1em;
    padding-bottom: .6666667em;
    padding-left: 1em
}

.prose-sm :where(thead th:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-left: 0
}

.prose-sm :where(thead th:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-right: 0
}

.prose-sm :where(tbody td, tfoot td):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding: .6666667em 1em
}

.prose-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-left: 0
}

.prose-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    padding-right: 0
}

.prose-sm :where(figure):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em
}

.prose-sm :where(figure>*):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0;
    margin-bottom: 0
}

.prose-sm :where(figcaption):not(:where([class~=not-prose], [class~=not-prose] *)) {
    font-size: .8571429em;
    line-height: 1.3333333;
    margin-top: .6666667em
}

.prose-sm :where(.prose-sm>:first-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-top: 0
}

.prose-sm :where(.prose-sm>:last-child):not(:where([class~=not-prose], [class~=not-prose] *)) {
    margin-bottom: 0
}

.btn {
    display: inline-flex;
    height: 2.5rem;
    flex-shrink: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    align-items: center;
    justify-content: center;
    /* border-radius: 9999px; */
    border-width: 1px;
    border-color: transparent;
    padding-left: .75rem;
    padding-right: .75rem;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.btn:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.btn:disabled {
    opacity: .75
}

.btn-sm {
    height: 1.75rem;
    padding-left: .75rem;
    padding-right: .75rem;
    font-size: .875rem;
    line-height: 1.25rem
}

.btn-xs {
    height: 1.5rem;
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: .75rem;
    line-height: 1rem
}

.btn-lg {
    height: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem
}

.btn-xl {
    height: 3rem;
    border-radius: .75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.75rem
}

.btn-xl:focus {
    border-radius: .75rem
}

.btn-2xl {
    height: 3.5rem;
    border-radius: .75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.25rem;
    line-height: 1.75rem
}

.btn-2xl:focus {
    border-radius: .75rem
}

.btn-square,
.btn-circle {
    width: 2rem;
    padding: 0
}

.btn-circle {
    border-radius: 9999px
}

.btn-circle:focus {
    border-radius: 9999px
}

.btn-square-lg,
.btn-circle-lg {
    height: 2.25rem;
    width: 2.25rem
}

.btn-square-md,
.btn-circle-md {
    height: 2rem;
    width: 2rem
}

.btn-square-sm,
.btn-circle-sm {
    height: 1.75rem;
    width: 1.75rem
}

.btn-square-xs,
.btn-circle-xs {
    height: 1.5rem;
    width: 1.5rem
}


*:focus {
    border-radius: 0;
    outline: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-color-swatch {
    border: 0;
    border-radius: 0
}

::-moz-color-swatch,
::-moz-focus-inner {
    border: 0
}

::-moz-focus-inner {
    padding: 0
}

.form-control-Search {
    display: block;
    width: 100%;
    border-radius: 2rem !important;
    background-color: #fff;
    border-width: 1px;
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.form-control-dropdown {
    display: block;
    width: 30%;
    border-radius: 2rem !important;
    background-color: #fff;
    border-width: 1px;
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    padding: .275rem .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.form-control-dropdown .css-13cymwt-control {
    background-color: #fff !important;
    border: none !important;

}


.form-control {
    display: block;
    width: 100%;
    border-radius: .5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
    padding: .375rem .75rem;
    line-height: 1.5rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.form-control:-webkit-autofill {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.form-control-Search:-webkit-autofill {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.form-control:autofill {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.form-control-Search:autofill {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.form-control:focus {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.form-control-Search:focus {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.form-control-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.25rem
}

.form-control:disabled {
    --tw-border-opacity: 1;
    border-color: rgb(249 250 251 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
}

.form-label {
    margin-bottom: .25rem;
    display: inline-block;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 300;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}

.form-label>small {
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
}

@media (min-width: 1024px) {
    .form-label>small {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

.checkbox-label {
    display: flex;
    align-items: center
}

.checkbox-label>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.checkbox-label {
    font-size: .875rem;
    line-height: 1.25rem
}

@media (min-width: 1024px) {
    .checkbox-label {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

.checkbox-label>span {
    font-weight: 500
}

.form-select {
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
    padding: .375rem .75rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.form-select:-webkit-autofill {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.form-select:autofill {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.form-select:focus {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity))
}

.form-select-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.25rem
}

.form-checkbox {
    height: 1.25rem;
    width: 1.25rem;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(245 158 11 / var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.form-checkbox:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity))
}

.form-checkbox[type=checkbox],
.form-checkbox[type=radio] {
    border-radius: .375rem
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    background-color: transparent;
    padding: 1rem
}

.flex-grow {
    text-align: start !important;
}

article p {
    text-align: start;
}

#next {
    background-color: #000;
    color: #fff;
}

.error {
    color: red;
    text-align: start !important;
}

.input--file span i {
    color: #9ca3af;
    font-size: 23px;
}

.input--file {
    position: absolute;
    top: 0;
    color: #7f7f7f;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 99;
}

.input--file input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.logo-upload {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: #f3f4f6;
}

.logo-upload img {

    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-size: cover;

}

.blog-upload {
    position: relative;
    width: 247px;
    height: 167px;
    border-radius: 10px;
    background-color: #f3f4f6;
}

.blog-upload img {
    height: 100%;
    border-radius: 10px;
    background-size: cover;

}

/* ======================================================== */
.css-13cymwt-control {
    background-color: #f3f4f6 !important;

}

textarea {
    background-color: #f3f4f6 !important;
    padding: 2%;

}

textarea,
.form-control {
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 4px !important;
    border-style: solid !important;
    border-width: 1px !important;
}

.ql-toolbar.ql-snow,
.ql-toolbar.ql-snow+.ql-container.ql-snow {
    background-color: #f3f4f6 !important;
}

.css-1jqq78o-placeholder {
    font-family: "Nunito", sans-serif !important;
    font-size: 17px;
}

.login-forget {
    background-color: hsl(0, 0%, 96%);
    /* height: 90vh; */
    display: flex;
    align-items: center;
}

/* ======================================================== */
.main_header {
    position: fixed;
    width: 100%;
    z-index: 9;
    background-color: #fff;
}

.Detils-btn-edit {
    background-color: #007bff;
    color: #fff !important;
    height: 45px;
    margin: 0 10px;
    border-radius: 10px;
}

.Detils-btn-Delete {
    background-color: #007bff;
    color: #fff !important;
    height: 45px;

    margin: 0 10px;
    border-radius: 10px;
}

.Detils-btn-preview {
    background-color: #007bff;
    color: #fff !important;
    height: 45px;

    margin: 0 10px;
    border-radius: 10px;
}

.Detils-btn-pay {
    background-color: #007bff;
    color: #fff !important;
    height: 45px;

    margin: 0 10px;
    border-radius: 10px;
}

.Detils-btn-addoffer {
    background-color: #007bff;
    color: #fff !important;
    font-size: 16px;
    height: 90%;
    margin: 0 10px;
    border-radius: 10px !important;

}

.Detils-btn-Skip {
    background-color: #007bff;
    color: #fff !important;
    padding: 10px;
    border-radius: 10px;
    margin: 0 0 0 10px;

}

.submit_account {
    background-color: #000;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
}

.filed_name {
    text-align: start;
    margin-bottom: 10px;

}

textarea {
    width: 100%;
}

.placeholder {
    color: #65657b;
    font-family: "Nunito", sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 10px;
}

.input:focus~.placeholder,
.input:not(:placeholder-shown)~.placeholder {
    transform: translateY(-10px) translateX(-5px) scale(0.75);
}

.input:not(:placeholder-shown)~.placeholder {
    color: #808097;
}

.input:focus~.placeholder {
    color: #808097;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 65% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.card {
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Shape-1,
.Shape-2,
.Shape-3 {
    width: 80px;
    height: 80px;
    margin: 10px;

}

.Shape-1 {
    border: 5px solid #f5f5f5;
    border-radius: 11px;
    padding: 2px;
}

.Shape-2 {
    border: 5px solid #f5f5f5;
    border-radius: 50%;
    padding: 2px;
}

.Shape-3 {
    border: 5px solid #f5f5f5;
    padding: 2px;
}

.Shape_2 {
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.Shape_3 {
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;

}

.Shape {
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;

    border-radius: 10px;
}

label {
    display: block;
}

.select__placeholder {
    color: #82757d !important;

}

.primary-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.primary-btn:hover {
    background-color: #386bc0;
    border-radius: 5px;
    color: #fff;
}

ol,
ul,
menu {
    list-style: auto;
    margin: 15px;
    padding-left: 2rem;
}


/* =============================================== */
h2 {
    color: #333;
    text-align: center;

    font-family: "Nunito", sans-serif;
    font-weight: bold;
    position: relative;
    margin: 30px 0 60px;
}



.col-center {
    margin: 0 auto;
    float: none !important;
}

.carousel {
    padding: 0 70px;
}

.carousel .carousel-item {
    color: #999;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    min-height: 290px;
}

.carousel .carousel-item .img-box {
    width: 135px;
    height: 135px;
    margin: 0 auto;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 50%;
}

.carousel .img-box img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
}

.carousel .testimonial {
    padding: 30px 0 10px;
}

.carousel .overview {
    font-style: italic;
}

.carousel .overview b {
    text-transform: uppercase;
    color: #7AA641;
}

.carousel-control-prev,
.carousel-control-next {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    top: 50%;
    background: none;
}

.carousel-control-prev i,
.carousel-control-next i {
    font-size: 68px;
    line-height: 42px;
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
}

.carousel-indicators {
    bottom: -40px;
}

.carousel-indicators li,
.carousel-indicators li.active {
    width: 12px;
    height: 12px;
    margin: 1px 3px;
    border-radius: 50%;
    border: none;
}

.carousel-indicators li {
    background: #999;
    border-color: transparent;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}

.carousel-indicators li.active {
    background: #555;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}



/* ========================================================= */

.btn-label {
    position: relative;
    left: -13px;
    display: inline-block;
    padding: 10px 12px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 10px 0 0 10px;
}

.btn-label-view {
    position: relative;
    left: -13px;
    display: inline-block;
    padding: 5px 12px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 10px 0 0 10px;
}

.btn-label-big {
    position: relative;
    left: -12px;
    display: inline-block;
    font-size: 18px;
    padding: 9px 12px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 10px 0 0 10px;
}


.btn-labeled {
    padding-top: 0;
    padding-bottom: 0;
}

.font-bold {
    font-weight: 800;
    font-size: 24px;
}

.forget-link {
    color: #007bff;
}

.forget-link:hover {
    color: #0037ff;
}

.Shape-0 {
    border-color: #0037ff;
}

.login-subhading {
    font-size: 13px;
}

.more-button {
    position: absolute;
    right: 10px;
    top: 10px;
}

.bg-black {
    background-color: rgba(16, 161, 16, 0.76) !important;
    color: white;
}

.lg\:-mx-12 {
    margin-left: 0px !important;
}

.tablelist-form {
    background-color: #fff;
    border: none;
    border-radius: 10px;
}


.modal-content {
    border: none;
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px !important;
}

.avatar-xs {
    width: 40px;
    height: 40px;
}

/* ============================================================== */


.SubmitList,
.detilspage,
.homepage,
.registerpage,
.offerpage,
.ticketspage,
.ticketviewpage,
.sumitpage {
    background-color: hsl(0, 0%, 96%);
}

.chatVeiw {
    overflow-y: scroll;

}

.profile img {
    width: 150px;
    height: 150px;
}

.chatVeiw::-webkit-scrollbar,
.SubmitList::-webkit-scrollbar,
.detilspage::-webkit-scrollbar,
.homepage::-webkit-scrollbar,
.registerpage::-webkit-scrollbar,
.offerpage::-webkit-scrollbar,
.ticketspage::-webkit-scrollbar,
.ticketviewpage::-webkit-scrollbar,
.sumitpage::-webkit-scrollbar {
    display: none;
}

.Ticketbody,
.ticket {
    background-color: #f5f5f5;
}

.meet {
    background-color: #f5f5f5;
    height: 100%;
}

.App {
    height: 100%;
    background-color: hsl(0, 0%, 96%);
}

footer {
    width: 100% !important;
    justify-content: center;
    bottom: 0;
    border-top: 1px solid #000;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.footerList {
    position: fixed;
}

.SubmitListlength {
    height: 85vh;
}

.more_link {
    display: flex;
    align-items: center;
}

.more_link link {
    margin: 10px;
}

.list_link {
    margin: 10px;
}

.yourwebsite-text {
    font-size: 22px;
    font-weight: 700;
}

.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.3rem !important;
}

.peer:checked~.peer-checked\:border-purple-500 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.detil-titel-home {
    margin-bottom: 2rem;
    /* background-color: #f9fafb; */
    padding: 10px;
    padding-left: 20px;
    border-radius: 10px;
    font-weight: 500;

}

.md\:bg-gray-50 {
    background-color: #f9fafb;
}

.card-img {
    height: 300px;
}

.card-img img {
    height: 100%;
}

.card-footer-img {
    width: 50px;
    height: 50px;
}

.card-footer-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;

}

.blog-title {
    font-size: 25px;
    font-weight: 500;
}

.read-post:hover {
    color: red;
    cursor: pointer;
}

.success-img {
    height: 100px;
    width: 100px;

    border-radius: 50%;
}

.success-img img {
    height: 100%;
}


.offerDropdownItem {
    background-color: #fff !important;
    color: #000 !important;
    box-shadow: none;
}

.offerDropdownItem:hover {
    background-color: none !important;
    box-shadow: none;

}

/* .btn-secondary:active:focus {
    box-shadow: none;
} */

.offerDropdownItem:focus {
    background-color: none !important;
    border: none !important;

}

.Blog-link:hover {
    color: #000;
}

.card-footer {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

.blogList-img {
    height: 167px;

}

.blogList-img img {
    background-size: cover;
    background-position: center center;
}


.Blog-view {
    width: 100%;
    height: 350px;
}

.Blog-view img {
    width: 100%;
    height: 100%;
}


.more-btn-websitlist::-webkit-scrollbar,
.more-btn-bloglist::-webkit-scrollbar {
    display: none;
}

.more-btn-bloglist {
    display: flex;
    justify-content: flex-end;
}

.more-btn-websitlist {
    display: flex;
    justify-content: flex-end;
}

/* ========================================================== */
.Categories-list {
    width: 150px;
    height: 150px;
    margin: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.Categories-list-img {
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 50%;

    background-color: #FFF;
}

.Categories-list-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.Categories-name {
    display: flex;
    justify-content: center;
}

.Categories-name span {
    background-color: #f2f4f7;
    padding: 3px;
    font-size: 12px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 300px;
    margin: 20px auto;
}

.append-buttons {
    text-align: center;
    margin-top: 20px;
}

.append-buttons button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #007aff;
    color: #007aff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: 13px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 300px;
    margin: 20px auto;
}

.append-buttons {
    text-align: center;
    margin-top: 20px;
}

.append-buttons button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #007aff;
    color: #007aff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: 13px;
}

.slider-1,
.slider-2,
.slider-3,
.slider-4,
.slider-5,
.slider-6,
.slider-7,
.slider-8,
.slider-9 {
    border-radius: 10px;
}

.slider-1 {
    background-color: #d1dcff;
}

.slider-2 {
    background-color: #d1f6ff;
}

.slider-3 {
    background-color: #fff5d1;
}

.slider-4 {
    background-color: #e5d1ff;
}

.slider-5 {
    background-color: #e4e4e4;
}

.slider-6 {
    background-color: #ffe7d1;
}

.slider-7 {
    background-color: #fffed1;
}

.slider-8 {
    background-color: #ffd1db;
}

.slider-9 {
    background-color: #ebd1ff;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    font-size: 12px;


}

.swiper-button-next::after,
.swiper-button-prev::after {
    color: #000;
}

.Categories-list a:hover {
    color: #000 !important;
}

.categories-singleView-img {
    width: 280px;
    height: 280px;
}

.categories-singleView-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.project {
    background-color: rgba(132, 248, 132, 0.596);
    padding: 0 10px;
}

/* ================================== */

.active-register,
.active-login,
.active-blogs,
.active-bloglist,
.active-Website,
.active-register,
.active-supporttickets {
    color: #007bff !important;
}

.bg-red {
    background-color: rgba(255, 0, 0, 0.464);
}

.offcanvas-sm,
.offcanvas-md,
.offcanvas-lg,
.offcanvas-xl,
.offcanvas-xxl,
.offcanvas {
    --vz-offcanvas-width: 60%;
}

.menubar {
    display: none;
}

.css-1fdsijx-ValueContainer {
    padding: 0 !important;
}

.blog-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: cover;
}

.custom-modal-body {
    background-color: transparent;
}

/* ============================================================== */
@media (max-width:768px) {
    .text-website {
        display: none;
    }
}

@media (max-width: 720px) {
    .more-btn-websitlist .btn {
        width: 12%;
    }

    .headerlink {
        display: none;
    }

    .menubar {
        display: inline-block;
    }

    .blogstatus {
        position: absolute;
        top: 0;
        right: 0;
    }

    .more-btn-bloglist .btn {
        width: 19%;
    }

    .more-btn-bloglist .Detils-btn-pay {
        width: 13%;
    }

    .Detils-btnpaymentStatus {
        width: 13% !important;

    }

    .text-website {
        display: none;
    }

    .m-mb-1 {
        margin-bottom: .25rem
    }

    .m-mb-10 {
        margin-bottom: 2.5rem
    }

    .m-mb-42 {
        margin-bottom: 10rem
    }

    body {
        text-align: start !important;
    }



    .btn-label-big {

        left: 0px;
    }

    .blog-img {
        width: 100%;
        height: 70%;
        background-size: cover;
    }

    .m-flex-wrap {
        flex-wrap: wrap
    }

    .m-w-full {
        width: 100%
    }
}

@media (max-width: 450px) {

    .container {
        text-align: start;
    }

    .gap-4 {
        gap: 0.5rem !important;
    }

    .w-20,
    .h-20 {
        width: 4rem !important;
        height: 4rem !important;
    }

    .font-bold {
        font-size: 19px;
    }

    .modal {
        left: -8px !important;
    }

    .btn-label-big {

        font-size: 15px;
        padding: 8px 12px;

    }

    .Detils-btn-edit {
        margin: 0 10px;
        font-size: 12px;

    }

    .Detils-btn-addoffer {
        font-size: 12px;
        /* color: green; */
    }

    /* .form-check-input,
    .link-primary,
    .HederId,
    .HederStatus,
    .text-uppercase {
        display: none !important;
    } */

    .text-sm {
        font-size: 0.85rem !important;
        line-height: 1.25rem;
    }

    .mani_header img {
        width: 78.96px !important;
        margin-right: 20px;
    }

    .mani_header {
        padding-left: 1rem !important;
    }




}

/* ===================================================================================================================== */
.modal-dialog {
    position: relative;
    width: 100%;
    max-width: 36rem
}

.modal-search {
    align-items: flex-start
}

@media (min-width: 1024px) {
    .modal-search {
        padding-top: 4rem
    }
}

.modal-search>.modal-dialog {
    width: 100%;
    max-width: none
}

@media (min-width: 1024px) {
    .modal-search>.modal-dialog {
        max-width: 65ch
    }
}

.toggle-container {
    display: flex;
    cursor: pointer;
    align-items: center
}

.toggle {
    display: block;
    height: 1.5rem;
    width: 2.5rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.toggle-knob {
    position: absolute;
    left: .25rem;
    top: .25rem;
    height: 1rem;
    width: 1rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

input:checked~.toggle-knob {
    transform: translate(100%)
}

input:checked~.toggle {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}

.static {
    position: static
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.-top-2 {
    top: -.5rem
}

.-top-2\.5 {
    top: -.625rem
}

.-top-3 {
    top: -.75rem
}

.bottom-4 {
    bottom: 1rem
}

.left-0 {
    left: 0
}

.left-1\/2 {
    left: 50%
}

.left-4 {
    left: 1rem
}

.right-0 {
    right: 0
}

.right-3 {
    right: .75rem
}

.right-4 {
    right: 1rem
}

.top-1\/2 {
    top: 50%
}

.top-4 {
    top: 1rem
}

.top-5 {
    top: 1.25rem
}

.top-6 {
    top: 1.5rem
}

.top-8 {
    top: 2rem
}

.z-0 {
    z-index: 0
}

.z-10 {
    z-index: 10
}

.z-40 {
    z-index: 40
}

.z-50 {
    z-index: 50
}

.-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
}

.-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.-ml-px {
    margin-left: -1px
}

.mb-0 {
    margin-bottom: 0
}

.mb-0\.5 {
    margin-bottom: .125rem
}

.mb-1 {
    margin-bottom: .25rem
}

.mb-1\.5 {
    margin-bottom: .375rem
}

.mb-10 {
    margin-bottom: 2.5rem
}

.mb-12 {
    margin-bottom: 3rem
}

.mb-22 {
    margin-bottom: 5rem
}

.mb-26 {
    margin-bottom: 6rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-3 {
    margin-bottom: .75rem
}

.mb-32 {
    margin-bottom: 8rem
}

.mb-4 {
    margin-bottom: 1rem
}

.mb-6 {
    margin-bottom: 1.5rem
}

.mb-8 {
    margin-bottom: 2rem
}

.ml-1 {
    margin-left: .25rem
}

.ml-3 {
    margin-left: .75rem
}

.ml-4 {
    margin-left: 1rem
}

.mr-0 {
    margin-right: 0
}

.mr-0\.5 {
    margin-right: .125rem
}

.mr-1 {
    margin-right: .25rem
}

.mr-1\.5 {
    margin-right: .375rem
}

.mr-2 {
    margin-right: .5rem
}

.mr-3 {
    margin-right: .75rem
}

.mr-4 {
    margin-right: 1rem
}

.mt-1 {
    margin-top: .25rem
}

.mt-2 {
    margin-top: .5rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-32 {
    margin-top: 8rem
}

.mt-4 {
    margin-top: 1rem
}

.mt-6 {
    margin-top: 1.5rem
}

.mt-8 {
    margin-top: 2rem
}

.mt-auto {
    margin-top: auto
}

.mt-px {
    margin-top: 1px
}

.ms-4 {
    margin-left: 5rem !important;
}

.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.flex {
    display: flex
}

.inline-flex {
    display: inline-flex
}

.table {
    display: table
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-10 {
    height: 2.5rem
}

.h-12 {
    height: 3rem
}

.h-16 {
    height: 4rem
}

.h-2 {
    height: .5rem
}

.h-20 {
    height: 5rem
}

.h-24 {
    height: 6rem
}

.h-3 {
    height: .75rem
}

.h-3\.5 {
    height: .875rem
}

.h-4 {
    height: 1rem
}

.h-40 {
    height: 10rem
}

.h-5 {
    height: 1.25rem
}

.h-6 {
    height: 1.5rem
}

.h-7 {
    height: 1.75rem
}

.h-8 {
    height: 2rem
}

.h-\[24px\] {
    height: 24px
}

.h-\[32px\] {
    height: 32px
}

.h-\[630px\] {
    height: 630px
}

.h-full {
    height: 100%
}

.h-screen {
    height: 100vh
}

.max-h-36 {
    max-height: 9rem
}

.min-h-\[250px\] {
    min-height: 250px
}

.min-h-screen {
    min-height: 100vh
}

.w-0 {
    width: 0px
}

.w-10 {
    width: 2.5rem
}

.w-16 {
    width: 4rem
}

.w-2 {
    width: .5rem
}

.w-20 {
    width: 5rem
}

.w-24 {
    width: 6rem
}

.w-3 {
    width: .75rem
}

.w-3\.5 {
    width: .875rem
}

.w-32 {
    width: 8rem
}

.w-4 {
    width: 1rem
}

.w-40 {
    width: 10rem
}

.w-5 {
    width: 1.25rem
}

.w-6 {
    width: 1.5rem
}

.w-60 {
    width: 15rem
}

.w-64 {
    width: 16rem
}

.w-7 {
    width: 1.75rem
}

.w-8 {
    width: 2rem
}

.w-\[1200px\] {
    width: 1200px
}

.w-\[128px\] {
    width: 128px
}

.w-\[91\.96px\] {
    width: 91.96px
}

.w-auto {
    width: auto
}

.w-full {
    width: 100%
}

.max-w-2xl {
    max-width: 42rem
}

.max-w-7xl {
    max-width: 80rem
}

.max-w-lg {
    max-width: 32rem
}

.max-w-md {
    max-width: 28rem
}

.max-w-none {
    max-width: none
}

.max-w-sm {
    max-width: 24rem
}

.max-w-xl {
    max-width: 36rem
}

.flex-1 {
    flex: 1 1 0%
}

.flex-none {
    flex: none
}

.flex-shrink-0,
.shrink-0 {
    flex-shrink: 0
}

.flex-grow {
    flex-grow: 1
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-4 {
    --tw-translate-y: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes appear-then-fade-out {

    0%,
    to {
        opacity: 0
    }

    10%,
    80% {
        opacity: 1
    }
}

.animate-appear-then-fade-out {
    animation: appear-then-fade-out 3s both
}

@keyframes ping {

    75%,
    to {
        transform: scale(2);
        opacity: 0
    }
}

.animate-ping {
    animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite
}

@keyframes pulse {
    50% {
        opacity: .5
    }
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
}

.cursor-default {
    cursor: default
}

.cursor-grab {
    cursor: grab
}

.cursor-pointer {
    cursor: pointer
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness)
}

.snap-start {
    scroll-snap-align: start
}

.list-inside {
    list-style-position: inside
}

.list-disc {
    list-style-type: disc
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
}

.flex-col {
    flex-direction: column
}

.flex-wrap {
    flex-wrap: wrap
}

.items-center {
    align-items: center
}

.items-baseline {
    align-items: baseline
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.justify-items-center {
    justify-items: center
}

.gap-1 {
    gap: .25rem
}

.gap-10 {
    gap: 2.5rem
}

.gap-12 {
    gap: 3rem
}

.gap-2 {
    gap: .5rem
}

.gap-3 {
    gap: .75rem
}

.gap-4 {
    gap: 1rem
}

.gap-6 {
    gap: 1.5rem
}

.gap-8 {
    gap: 2rem
}

.space-x-0>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-0\.5>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.125rem * var(--tw-space-x-reverse));
    margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-1>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-1\.5>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-2>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-3>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.75rem * var(--tw-space-x-reverse));
    margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-4>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-6>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-8>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-0>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse))
}

.space-y-0\.5>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.125rem * var(--tw-space-y-reverse))
}

.space-y-1>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse))
}

.space-y-3>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse))
}

.space-y-4>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-y-6>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
}

.space-y-8>:not([hidden])~:not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.overflow-hidden {
    overflow: hidden
}

.overflow-x-auto {
    overflow-x: auto
}

.overflow-y-auto {
    overflow-y: auto
}

.overflow-y-hidden {
    overflow-y: hidden
}

.overflow-y-scroll {
    overflow-y: scroll
}

.text-ellipsis {
    text-overflow: ellipsis
}

.break-all {
    word-break: break-all
}

.rounded {
    border-radius: .25rem
}

.rounded-3xl {
    border-radius: 1.5rem
}

.rounded-\[22\.5\%\] {
    border-radius: 22.5%
}

.rounded-\[23\%\] {
    border-radius: 23%
}

.rounded-full {
    border-radius: 9999px
}

.rounded-lg {
    border-radius: .5rem
}

.rounded-md {
    border-radius: .375rem
}

.rounded-none {
    border-radius: 0
}

.rounded-xl {
    border-radius: .75rem
}

.rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px
}

.rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem
}

.rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem
}

.rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.border {
    border-width: 1px
}

.border-l {
    border-left-width: 1px
}

.border-r {
    border-right-width: 1px
}

.border-t {
    border-top-width: 1px
}

.border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(156 163 175 / var(--tw-border-opacity))
}

.border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgb(187 247 208 / var(--tw-border-opacity))
}

.border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity))
}

.border-slate-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity))
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity))
}

.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity))
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.bg-gray-50\/50 {
    background-color: #f9fafb80
}

.bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity))
}

.bg-gray-950\/50 {
    background-color: #03071280
}

.bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity))
}

.bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity))
}

.bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity))
}

.bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity))
}

.bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity))
}

.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity))
}

.bg-orange-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 247 237 / var(--tw-bg-opacity))
}

.bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity))
}

.bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity))
}

.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity))
}

.bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity))
}

.bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))
}

.bg-slate-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 6 23 / var(--tw-bg-opacity))
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity))
}

.bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 252 232 / var(--tw-bg-opacity))
}

.bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity))
}

.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.from-gray-600 {
    --tw-gradient-from: #4b5563 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.to-black {
    --tw-gradient-to: #000 var(--tw-gradient-to-position)
}

.bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text
}

.fill-current {
    fill: currentColor
}

.fill-gray-600 {
    fill: #4b5563
}

.p-1 {
    padding: .25rem
}

.p-2 {
    padding: .5rem
}

.p-3 {
    padding: .75rem
}

.p-4 {
    padding: 1rem
}

.p-6 {
    padding: 1.5rem
}

.px-auto {
    padding-left: auto;
    padding-right: auto
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem
}

.px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem
}

.px-2 {
    padding-left: .5rem;
    padding-right: .5rem
}

.px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0
}

.py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem
}

.py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.pb-20 {
    padding-bottom: 5rem
}

.pb-24 {
    padding-bottom: 6rem
}

.pb-4 {
    padding-bottom: 1rem
}

.pb-40 {
    padding-bottom: 10rem
}

.pl-1 {
    padding-left: .25rem
}

.pl-20 {
    padding-left: 5rem
}

.pl-4 {
    padding-left: 1rem
}

.pr-20 {
    padding-right: 5rem
}

.pr-3 {
    padding-right: .75rem
}

.pr-4 {
    padding-right: 1rem
}

.pt-10 {
    padding-top: 2.5rem
}

.pt-20 {
    padding-top: 5rem
}

.pt-6 {
    padding-top: 1.5rem
}

.text-center {
    text-align: center
}

.font-\[\'Inter\'\] {
    font-family: Inter
}

.font-sans {
    font-family: Inter, ui-sans-serif, system-ui
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
}

.text-6xl {
    font-size: 3.75rem;
    line-height: 1
}

.text-\[120px\] {
    font-size: 120px
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem
}

.font-bold {
    font-weight: 700
}

.font-extrabold {
    font-weight: 800
}

.font-medium {
    font-weight: 500
}

.font-semibold {
    font-weight: 600
}

.italic {
    font-style: italic
}

.leading-5 {
    line-height: 1.25rem
}

.leading-none {
    line-height: 1
}

.leading-normal {
    line-height: 1.5
}

.tracking-tight {
    letter-spacing: -.025em
}

.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity))
}

.text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity))
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity))
}

.text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity))
}

.text-orange-500 {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity))
}

.text-red-300 {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity))
}

.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))
}

.text-slate-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}

.text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}

.text-slate-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))
}

.text-transparent {
    color: transparent
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity))
}

.line-through {
    text-decoration-line: line-through
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.opacity-0 {
    opacity: 0
}

.opacity-100 {
    opacity: 1
}

.opacity-75 {
    opacity: .75
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline {
    outline-style: solid
}

.outline-4 {
    outline-width: 4px
}

.outline-offset-1 {
    outline-offset: 1px
}

.outline-gray-100 {
    outline-color: #f3f4f6
}

.outline-purple-200 {
    outline-color: #e9d5ff
}

.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))
}

.ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity))
}

.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
}

.duration-150 {
    transition-duration: .15s
}

.duration-200 {
    transition-duration: .2s
}

.duration-300 {
    transition-duration: .3s
}

.ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.scrollbar {
    scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial)
}

.scrollbar::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
    border-radius: var(--scrollbar-track-radius)
}

.scrollbar::-webkit-scrollbar-track:hover {
    background-color: var(--scrollbar-track-hover, var(--scrollbar-track))
}

.scrollbar::-webkit-scrollbar-track:active {
    background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)))
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    border-radius: var(--scrollbar-thumb-radius)
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb))
}

.scrollbar::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)))
}

.scrollbar::-webkit-scrollbar-corner {
    background-color: var(--scrollbar-corner);
    border-radius: var(--scrollbar-corner-radius)
}

.scrollbar::-webkit-scrollbar-corner:hover {
    background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner))
}

.scrollbar::-webkit-scrollbar-corner:active {
    background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)))
}

.scrollbar {
    scrollbar-width: auto
}

.scrollbar::-webkit-scrollbar {
    display: block;
    width: var(--scrollbar-width, 16px);
    height: var(--scrollbar-height, 16px)
}

.scrollbar-thin {
    scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial)
}

.scrollbar-thin::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
    border-radius: var(--scrollbar-track-radius)
}

.scrollbar-thin::-webkit-scrollbar-track:hover {
    background-color: var(--scrollbar-track-hover, var(--scrollbar-track))
}

.scrollbar-thin::-webkit-scrollbar-track:active {
    background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)))
}

.scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    border-radius: var(--scrollbar-thumb-radius)
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb))
}

.scrollbar-thin::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)))
}

.scrollbar-thin::-webkit-scrollbar-corner {
    background-color: var(--scrollbar-corner);
    border-radius: var(--scrollbar-corner-radius)
}

.scrollbar-thin::-webkit-scrollbar-corner:hover {
    background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner))
}

.scrollbar-thin::-webkit-scrollbar-corner:active {
    background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)))
}

.scrollbar-thin {
    scrollbar-width: thin
}

.scrollbar-thin::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px
}

.scrollbar-track-transparent {
    --scrollbar-track: transparent !important
}

.scrollbar-thumb-gray-100 {
    --scrollbar-thumb: #f3f4f6 !important
}

.scrollbar-thumb-transparent {
    --scrollbar-thumb: transparent !important
}

.scrollbar-thumb-rounded-full {
    --scrollbar-thumb-radius: 9999px
}

.scrollbar-h-\[0px\] {
    --scrollbar-height: 0px
}

.last\:mb-0:last-child {
    margin-bottom: 0
}

.group.has-items .group-\[\.has-items\]\:pt-2 {
    padding-top: .5rem
}

.peer:checked~.peer-checked\:border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.peer:checked~.peer-checked\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity))
}

.peer:checked~.peer-checked\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity))
}

.peer:checked~.peer-checked\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity))
}

.peer:checked~.peer-checked\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity))
}

.group[data-type=coupon] .group-data-\[type\=coupon\]\:block,
.group[data-type=url] .group-data-\[type\=url\]\:block {
    display: block
}

.prose-img\:rounded-xl :is(:where(img):not(:where([class~=not-prose], [class~=not-prose] *))) {
    border-radius: .75rem
}

.hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity))
}

.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.hover\:bg-slate-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity))
}

.hover\:fill-gray-900:hover {
    fill: #111827
}

.hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
}

.hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}

.hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #a33c3c00), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:outline-purple-200:hover {
    outline-color: #e9d5ff
}

.focus\:z-10:focus {
    z-index: 10
}

.focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgb(147 197 253 / var(--tw-border-opacity))
}

.focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.active\:bg-gray-100:active {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.active\:text-gray-500:active {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.active\:text-gray-700:active {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}

.border-shadow {
    border-width: 0.01px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.border-shadow:hover {
    border-color: #6569de;
}

.App-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    justify-content: center;
    align-items: center;
}

.App-footer-scroll {
    position: relative;
}


@media (min-width: 640px) {
    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }

    .sm\:flex {
        display: flex
    }

    .sm\:hidden {
        display: none
    }

    .sm\:w-full {
        width: 100%
    }

    .sm\:max-w-2xl {
        max-width: 42rem
    }

    .sm\:max-w-lg {
        max-width: 32rem
    }

    .sm\:max-w-md {
        max-width: 28rem
    }

    .sm\:max-w-sm {
        max-width: 24rem
    }

    .sm\:max-w-xl {
        max-width: 36rem
    }

    .sm\:flex-1 {
        flex: 1 1 0%
    }

    .sm\:translate-y-0 {
        --tw-translate-y: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .sm\:scale-100 {
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .sm\:scale-95 {
        --tw-scale-x: .95;
        --tw-scale-y: .95;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .sm\:items-center {
        align-items: center
    }

    .sm\:justify-between {
        justify-content: space-between
    }

    .sm\:p-6 {
        padding: 1.5rem
    }

    .sm\:px-0 {
        padding-left: 0;
        padding-right: 0
    }
}

@media (min-width: 768px) {
    .md\:flex {
        display: flex
    }

    .md\:hidden {
        display: none
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:items-center {
        align-items: center
    }

    .md\:justify-center {
        justify-content: center
    }

    .md\:gap-4 {
        gap: 1rem
    }

    .md\:rounded-lg {
        border-radius: .5rem
    }

    .md\:bg-gray-50 {
        --tw-bg-opacity: 1;
        background-color: rgb(249 250 251 / var(--tw-bg-opacity))
    }

    .md\:p-3 {
        padding: .75rem
    }

    .md\:text-xs {
        font-size: .75rem;
        line-height: 1rem
    }

    .md\:hover\:shadow:hover {
        --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
        --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }
}

@media (min-width: 1024px) {
    .lg\:col-span-3 {
        grid-column: span 3 / span 3
    }

    .lg\:-mx-12 {
        margin-left: -3rem;
        margin-right: -3rem
    }

    .lg\:-ml-6 {
        margin-left: -1.5rem
    }

    .lg\:mb-0 {
        margin-bottom: 0
    }

    .lg\:mb-1 {
        margin-bottom: 1rem
    }

    .lg\:mb-12 {
        margin-bottom: 3rem
    }

    .lg\:mr-1 {
        margin-right: .25rem
    }

    .lg\:mr-4 {
        margin-right: 1rem
    }

    .lg\:mr-6 {
        margin-right: 1.5rem
    }

    .lg\:block {
        display: block
    }

    .lg\:flex {
        display: flex
    }

    .lg\:grid {
        display: grid
    }

    .lg\:hidden {
        display: none
    }

    .lg\:h-20 {
        height: 5rem
    }

    .lg\:h-24 {
        height: 6rem
    }

    .lg\:w-20 {
        width: 5rem
    }

    .lg\:w-24 {
        width: 6rem
    }

    .lg\:w-auto {
        width: auto
    }

    .lg\:max-w-xs {
        max-width: 20rem
    }

    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .lg\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr))
    }

    .lg\:items-center {
        align-items: center
    }

    .lg\:gap-4 {
        gap: 1rem
    }

    .lg\:space-x-3>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(.75rem * var(--tw-space-x-reverse));
        margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
    }

    .lg\:rounded-lg {
        border-radius: .5rem
    }

    .lg\:rounded-xl {
        border-radius: .75rem
    }

    .lg\:bg-gray-50 {
        --tw-bg-opacity: 1;
        background-color: rgb(249 250 251 / var(--tw-bg-opacity))
    }

    .lg\:p-3 {
        padding: .75rem
    }

    .lg\:px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .lg\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .lg\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .lg\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem
    }

    .lg\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }

    .lg\:text-xs {
        font-size: .75rem;
        line-height: 1rem
    }

    .lg\:hover\:shadow:hover {
        --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
        --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }
}

@media (min-width: 1280px) {
    .xl\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }
}